@font-face {
    font-family: 'SF-Pro-Text';
    src: url("./assets/font/SF-Pro-Text-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'SF-Pro-Text';
    font-weight: bold;
    src: url("./assets/font/SF-Pro-Text-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'Open Sans';
  src:  url('./assets/font/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src:  url('./assets/font/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src:  url('./assets/font/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:  url('./assets/font/SourceSansPro-Light.ttf') format('truetype'), local('Avenir');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:  url('./assets/font/SourceSansPro-Regular.ttf') format('truetype'), local('Avenir');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/font/SourceSansPro-SemiBold.ttf') format('truetype'), local('Avenir');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:  url('./assets/font/SourceSansPro-Bold.ttf') format('truetype'), local('Avenir');
  font-weight: 700;
  font-display: swap;
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100vh;
  font-size: 16px;
}

body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', 'Avenir', sans-serif;
  font-weight: 300;
  outline: none;  
}

h1, h2, h3 {
  margin-bottom: 0;
}

p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
